<template>
  <div class="flix-form-group">
    {{ $t('constants.months')[date.getMonth() + 1] }} {{ date.getFullYear() }}
    <div class="flix-html-h1">{{ $getNullsBefore(date.getDate()) }}</div>
    {{ $t('constants.weekdays')[$getWeekday(date)] }}
  </div>
</template>

<script>
export default {
  props: { date: Date }
}
</script>

<style scoped lang="sass">

.flix-html-h1
  margin-top: 0
  margin-bottom: 0
</style>
